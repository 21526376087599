<div class="page-wrapper">
  <header class="main-header">
    <div class="main-box">
      <div class="auto-container">
        <div class="outer-container clearfix">
          <div class="logo-box">
            <div class="logo">
              <a href="/">
                <img src="assets/logos/logo.svg" i18n-title alt="logotipo">
              </a>
            </div>
          </div>
          <div class="nav-outer clearfix">
            <nav class="main-menu">
              <div class="navbar-header">
                <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse"
                  aria-expanded="true" (click)="toogleMenu()">
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                  <span class="icon-bar"></span>
                </button>
              </div>
              <div class="navbar-collapse scroll-nav clearfix collapse" [class.in]="showMenu">
                <ul class="navigation clearfix" (click)="toogleMenu()">
                  <li>
                    <a [ngx-scroll-to]="'#features'" href="#features"
                      i18n="Menu options: Features@@menuOptionsFeatures">Caracteristicas</a>
                  </li>
                  <!-- <li>
                    <a [ngx-scroll-to]="'#contact'" href="#contact"
                      i18n="Menu options: Pricing@@menuOptionsContact">Tarifas</a>
                  </li> -->
                  <li>
                    <a [ngx-scroll-to]="'#contact'" href="#contact"
                      i18n="Menu options: try for free@@menuOptionsTryForFree">Prueba gratuita</a>
                  </li>
                  <li>
                    <a [ngx-scroll-to]="'#download'" href="#download"
                      i18n="Menu options: Download@@menuOptionsDownload">Descargar</a>
                  </li>
                </ul>
              </div>
            </nav>
            <div class="other-links">
              <a href="https://iqsales.value-iq.com/" class="theme-btn btn-style-one"
                i18n="Menu options: LogIn@@menuOptionsLogIn">
                Iniciar
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>


  <section class="main-banner banner-one" id="banner"
    style="background-image:url(assets/spring/background/banner-bg-1.png);">
    <div class="auto-container">
      <div class="row clearfix">
        <div class="text-column col-md-6 col-sm-12 col-xs-12">
          <div class="inner">
            <h4 i18n="Head section: text over headline@@headSectionTextOverHeadline">IQ sales</h4>
            <h1 i18n="Head section: Headline@@headSectionHeadline">
              La app CRM que potencia las ventas de tu comercio.
            </h1>
            <p i18n="Head section: sub-head@@headSectionSubHead">
              Con IQSales realiza el seguimiento de tus clientes potenciales desde el primer momento. Conoce a tu
              cliente y mejore su experiencia
              de compra.
            </p>
            <a [ngx-scroll-to]="'#contact'" class="theme-btn btn-style-two">
              <span i18n="Head section: button@@headSectionButton"> Probarlo </span>
              <span class="icon ti-arrow-circle-right"></span>
            </a>
          </div>
        </div>
        <div class="image-column col-md-6 col-sm-12 col-xs-12">
          <div class="inner">
            <figure class="image">
              <img src="assets/screenshots/{{language}}/android-ios-web.png" alt="">
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="products-section" id="features">
    <div class="auto-container">
      <div class="sec-title">
        <div class="icon">
          <figure class="image">
            <img src="assets/spring/icons/icon-2.png" i18n="improve sales icon" alt="icono de mejorar ventas">
          </figure>
        </div>
        <h2 i18n="features section: title section@@featuresSectionTitleSection">
          Conoce a tus clientes y cuídalos ofreciendo lo que necesitan en todo momento.
        </h2>
        <div class="desc-text">
          <p i18n="features section: description section@@featuresSectionDescriptionSection">
            Con nuestra app para móviles podrás personalizar la experiencia de compra y conocer las necesidades de tu
            cliente de manera
            anticipada, garantizando un mayor porcentaje de ventas. El uso de nuestra aplicación nos permite registrar
            los
            clientes de manera rápida y amplia para crear una base de datos propia.
          </p>
        </div>
      </div>
      <div class="services-outer clearfix">
        <div class="service-style-one col-md-4 col-sm-6 col-xs-12">
          <div class="inner-box">
            <div class="icon">
              <figure class="image">
                <img src="assets/spring/icons/icon-10.png" alt="">
              </figure>
            </div>
            <h3>
              <a [ngx-scroll-to]="'#customer'"
                i18n="description section, customer: title@@descriptionSectionCustomerTitle" href="#customer">
                Cliente
              </a>
            </h3>
            <div class="text">
              <p i18n="description section, customer: explanation@@descriptionSectionCustomerExplanation">
                Con IQSales tendrás una base de clientes actualizada que te permitirá trabajar en tiempo real con el
                cliente en tienda.
              </p>
            </div>
            <div class="link-box">
              <a [ngx-scroll-to]="'#customer'" href="#customer">
                <span i18n="see more@@customerSeeMore">
                  Ver más
                </span>
                <span class="icon ti-arrow-circle-right"></span>
              </a>
            </div>
          </div>
        </div>
        <div class="service-style-one col-md-4 col-sm-6 col-xs-12">
          <div class="inner-box">
            <div class="icon">
              <figure class="image">
                <img src="assets/spring/icons/icon-9.png" alt="">
              </figure>
            </div>
            <h3>
              <a [ngx-scroll-to]="'#salesRep'" href="#salesRep" i18n="SalesRep@@salesRep">
                Comerciales
              </a>
            </h3>
            <div class="text">
              <p i18n="description section, SalesRep: explanation@@descriptionSectionSalesRepExplanation">
                Facilitamos la labor diaria de los comerciales con un registro de las visitas, citas y tareas
                relacionadas con cada cliente.
              </p>
            </div>
            <div class="link-box">
              <a [ngx-scroll-to]="'#salesRep'" href="#salesRep">
                <span i18n="see more@@salesRepSeeMore">
                  Ver más
                </span>
                <span class="icon ti-arrow-circle-right"></span>
              </a>
            </div>
          </div>
        </div>
        <div class="service-style-one col-md-4 col-sm-6 col-xs-12">
          <div class="inner-box">
            <div class="icon">
              <figure class="image">
                <img src="assets/spring/icons/icon-6.png" alt="">
              </figure>
            </div>
            <h3>
              <a [ngx-scroll-to]="'#gestion'" href="#gestion"
                i18n="description section, managment: title@@descriptionSectionManagementTitle">
                Gestión
              </a>
            </h3>
            <div class="text">
              <p i18n="description section, managment: explanation@@descriptionSectionManagementExplanation">
                IQ Sales puede registrar un historial de emails, llamadas y notas que nos sirven para monitorizar la
                relación que se tiene
                con un cliente.
              </p>
            </div>
            <div class="link-box">
              <a [ngx-scroll-to]="'#gestion'" href="#gestion">
                <span i18n="see more@@ManagementSeeMore">
                  Ver más
                </span>
                <span class="icon ti-arrow-circle-right"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="features-section" id="customer">
    <div class="auto-container">
      <div class="sec-title centered">
        <div class="icon">
          <figure class="image">
            <img src="assets/spring/icons/icon-10.png" alt="">
          </figure>
        </div>
        <h2 i18n="Customer section header: title@@customerSectionHeaderTitle">Cliente</h2>
        <div class="desc-text">
          <p i18n="Customer section header: subtitle@@customerSectionHeaderSubtitle">
            Fideliza a tus clientes conociéndolos al detalle y ofreciendo una experiencia de compra personalizada.
          </p>
        </div>
      </div>
      <div class="row clearfix">
        <div class="features-column col-md-5 col-sm-12 col-xs-12">
          <div class="feature-block-one" (mouseenter)="carrousels.customer = 0">
            <div class="inner-box">
              <div class="icon">
                <figure class="image">
                  <img src="assets/spring/icons/icon-3.png" alt="">
                </figure>
              </div>
              <h3 (click)="carrousels.customer = 0" i18n="Ofers">
                Ofertas
              </h3>
              <div class="text" i18n="Ofers description@@offersDescription">
                Nuestro apartado de ofertas te permite personalizar por cliente y realizar una oferta presencial
                registrada para poder alargar
                el proceso de venta más tiempo y realizar impactos comerciales constantes sin perder la venta. Tienes la
                opción de mandar una oferta al momento por email al cliente, realizar una nueva o modificar las
                existentes.
              </div>
            </div>
          </div>
          <div class="feature-block-one" (mouseenter)="carrousels.customer = 1">
            <div class="inner-box">
              <div class="icon">
                <figure class="image">
                  <img src="assets/spring/icons/icon-8.png" alt="">
                </figure>
              </div>
              <h3 (click)="carrousels.customer = 1" i18n="Surveys@@surveysCarrousels">
                Encuestas
              </h3>
              <div class="text" i18n="Surveys description@@surveysDescription">
                Crea una encuesta que luego puedas pasar a tus clientes para conocer mejor sus intereses. Puedes generar
                estadísticas y ver
                los resultados en una sola vistazo. De esta forma podrás segmentar a tus clientes y aplicar estrategias
                de
                marketing en base a sus intereses.
              </div>
            </div>
          </div>
          <div class="feature-block-one" (mouseenter)="carrousels.customer = 2">
            <div class="inner-box">
              <div class="icon">
                <figure class="image">
                  <img src="assets/spring/icons/icon-23.png" alt="">
                </figure>
              </div>
              <h3 (click)="carrousels.customer = 2" i18n="Purchases@@purchasesCarrousels">
                Tickets de ventas
              </h3>
              <div class="text" i18n="Purchases description@@purchasesDescription">
                Agrupa en un mismo lugar todos los tickets de venta de un mismo cliente. Al tener un histórico de compra
                de productos en
                el momento de realizar la compra nos permite aplicar de manera concreta técnicas de cross selling y up
                selling.
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-column col-md-6 col-md-offset-1 col-sm-12 col-xs-12">
          <div class="inner">
            <ul class="image-carousel-one single-item-carousel owl-theme owl-carousel owl-loaded owl-drag">
              <li [class.-is-hidden]="carrousels.customer !== 0">
                <span class="lightbox-image">
                  <img src="assets/screenshots/{{language}}/offer.png" i18n-alt="offer screenshoot"
                    alt="Captura de pantalla de la vista de oferta">
                </span>
              </li>
              <li [class.-is-hidden]="carrousels.customer !== 1">
                <span class="lightbox-image">
                  <img src="assets/screenshots/{{language}}/surveys.png" i18n-alt="surveys screenshoot"
                    alt="Captura de pantalla de la vista de encuestas">
                </span>
              </li>
              <li [class.-is-hidden]="carrousels.customer !== 2">
                <span class="lightbox-image">
                  <img src="assets/screenshots/{{language}}/purchase.png" i18n-alt="purchase screenshoot"
                    alt="Captura de pantalla de la vista de tickets de compra">
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="features-section alternate" id="salesRep">
    <div class="auto-container">
      <div class="sec-title centered">
        <div class="icon">
          <figure class="image">
            <img src="assets/spring/icons/icon-9.png" alt="">
          </figure>
        </div>
        <h2 i18n="SalesRep section header: title@@salesRepSectionHeaderTitle">
          Comerciales
        </h2>
        <div class="desc-text" i18n="SalesRep section header: description@@salesRepSectionHeaderdescription">
          Lleva un registro fluido de las interacciones de los comerciales con los clientes y no pierdas oportunidades
          de venta.
        </div>
      </div>

      <div class="row clearfix">
        <div class="carousel-column col-md-5 col-sm-12 col-xs-12">
          <div class="inner">
            <ul class="image-carousel-one single-item-carousel owl-theme owl-carousel owl-loaded owl-drag">
              <li [class.-is-hidden]="carrousels.salesRep !== 0">
                <span class="lightbox-image">
                  <img src="assets/screenshots/{{language}}/visit.png" i18n-alt="visits screenshoot"
                    alt="Captura de pantalla de la vista de visita">
                </span>
              </li>
              <li [class.-is-hidden]="carrousels.salesRep !== 1">
                <span class="lightbox-image">
                  <img src="assets/screenshots/{{language}}/appointments.png" i18n-alt="appointments screenshoot"
                    alt="Captura de pantalla de la vista de citas">
                </span>
              </li>
              <li [class.-is-hidden]="carrousels.salesRep !== 2">
                <span class="lightbox-image">
                  <img src="assets/screenshots/{{language}}/tasks.png" i18n-alt="tasks screenshoot"
                    alt="Captura de pantalla de la vista de tareas">
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="features-column col-md-4 col-md-offset-2 col-sm-12 col-xs-12">
          <div class="feature-block-one" (mouseenter)="carrousels.salesRep = 0">
            <div class="inner-box">
              <div class="icon">
                <figure class="image">
                  <img src="assets/spring/icons/icon-24.png" alt="">
                </figure>
              </div>
              <h3 i18n="Visits@@visitsCarrousel" (click)="carrousels.salesRep = 0">
                Visitas
              </h3>
              <div class="text" i18n="Visits description@@visitsDescription">
                Registra todas las visitas realizadas de tus clientes y los productos vendidos o en los que ha mostrado
                interés. Dentro de
                cada visita podrás ver los productos en los que se ha interesado tu cliente previamente y realizar una
                oferta
                al momento
              </div>
            </div>
          </div>
          <div class="feature-block-one" (mouseenter)="carrousels.salesRep = 1">
            <div class="inner-box">
              <div class="icon">
                <figure class="image">
                  <img src="assets/spring/icons/icon-11.png" alt="">
                </figure>
              </div>
              <h3 i18n="Appointments@@appointmentsCarrousels" (click)="carrousels.salesRep = 1">
                Citas
              </h3>
              <div class="text" i18n="Appointments description@@appointmentsDescription">
                En esta sección tendrás un historial de citas realizadas y pendientes con los clientes. Tienes opción de
                crear una cita nueva
                con fecha y hora de inicio y finalización.
              </div>
            </div>
          </div>
          <div class="feature-block-one" (mouseenter)="carrousels.salesRep = 2">
            <div class="inner-box">
              <div class="icon">
                <figure class="image">
                  <img src="assets/spring/icons/icon-26.png" alt="">
                </figure>
              </div>
              <h3 i18n="Tasks@@tasksCarrousels" (click)="carrousels.salesRep = 2">
                Tareas
              </h3>
              <div class="text" i18n="Tasks description@@tasksDescription">
                Nuestro sistema de tareas te permitirá prospectar sobre clientes potencialmente interesados y te dará
                una base de clientes
                sobre los que poder impactar en campañas efectivas.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="features-section alternate" id="gestion">
    <div class="auto-container">
      <div class="sec-title centered">
        <div class="icon">
          <figure class="image">
            <img src="assets/spring/icons/icon-9.png" alt="">
          </figure>
        </div>
        <h2 i18n="Managment section header: title@@managementSectionHeaderTitle">
          Gestión
        </h2>
        <div class="desc-text" i18n="Managment section header: description@@managementSectionHeaderDescription">
          Podrás ver de manera sencilla y rápida los canales de comunicación y su efectividad, lo que te permitirá
          aplicar estrategias
          de comunicación correctas basadas en las experiencias pasadas de tus clientes.
        </div>
      </div>
      <div class="row clearfix">
        <div class="features-column col-md-5 col-sm-12 col-xs-12">
          <div class="feature-block-one" (mouseenter)="carrousels.management = 0">
            <div class="inner-box">
              <div class="icon">
                <figure class="image">
                  <img src="assets/spring/icons/icon-25.png" alt="">
                </figure>
              </div>
              <h3 i18n="Emails@@emailsCarrousels" (click)="carrousels.management = 0">
                Emails
              </h3>
              <div i18n="Emails description@@emailsDescription">
                Convierte tu historial de emails con un mismo cliente en una conversación tipo chat. La mejor forma de
                ver la comunicación
                con tu cliente de una forma mucho más fluida y directa.
              </div>
            </div>
          </div>
          <div class="feature-block-one" (mouseenter)="carrousels.management = 1">
            <div class="inner-box">
              <div class="icon">
                <figure class="image">
                  <img src="assets/spring/icons/icon-22.png" alt="">
                </figure>
              </div>
              <h3 i18n="Notes@@notesCarrousels" (click)="carrousels.management = 1">
                Notas
              </h3>
              <div class="text" i18n="Notes description@@notesDescription">
                Toma notas de todo lo que necesites relacionado con tus clientes y ventas. No pierdas detalle de nada y
                accede siempre que
                lo necesites a ellas.
              </div>
            </div>
          </div>
          <div class="feature-block-one" (mouseenter)="carrousels.management = 2">
            <div class="inner-box">
              <div class="icon">
                <figure class="image">
                  <img src="assets/spring/icons/icon-20.png" alt="">
                </figure>
              </div>
              <h3 i18n="Calls@@calls" (click)="carrousels.management = 2">
                Llamadas
              </h3>
              <div class="text" i18n="Calls description@@callsDescription">
                Realiza llamadas a tus clientes desde la app o añade manualmente una llamada, de esta forma podrás
                llevar el registro de
                cualquier tipo de comunicación entre el comercial y el cliente, especificando además los resultados de
                la
                misma.
              </div>
            </div>
          </div>
        </div>
        <div class="carousel-column col-md-6 col-md-offset-1 col-sm-12 col-xs-12">
          <div class="inner">
            <ul class="image-carousel-one single-item-carousel owl-theme owl-carousel owl-loaded owl-drag">
              <li [class.-is-hidden]="carrousels.management !== 0">
                <span class="lightbox-image">
                  <img src="assets/screenshots/{{language}}/email-chat.png" i18n-alt="email chat screenshoot"
                    alt="Captura de pantalla de la vista de chat">
                </span>
              </li>
              <li [class.-is-hidden]="carrousels.management !== 1">
                <span class="lightbox-image">
                  <img src="assets/screenshots/{{language}}/notes.png" i18n-alt="notes screenshoot"
                    alt="Captura de pantalla de la vista de notas">
                </span>
              </li>
              <li [class.-is-hidden]="carrousels.management !== 2">
                <span class="lightbox-image">
                  <img src="assets/screenshots/{{language}}/calls.png" i18n-alt="calls screenshoot"
                    alt="Captura de pantalla de la vista de llamadas">
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="plans-section" id="pricing">
    <div class="auto-container">
      <div class="row clearfix">
        <div class="title-column col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <div class="inner">
            <h2 i18n="Pricing section: title@@pricingSectionTitle">
              Precio ajustable, tu decides.
            </h2>
            <div class="text" i18n="Pricing section: description@@pricingSectionDescription">
              Hay diferentes planes todos ajustables a tus necesidades.
            </div>
          </div>
        </div>
        <div class="plans-col col-lg-9 col-md-9 col-sm-12 col-xs-12">
          <div class="row clearfix">
            <div class="plan-column col-md-4 col-sm-4 col-xs-12">
              <div class="inner-box">
                <h5 i18n="Subscription price, the cheapper one planning@@subscriptionPlanning1Price">
                  0€/mes
                </h5>
                <p i18n="per user, subscription 1@@perUser" class="per-user">
                  per user
                </p>
                <h3 i18n="Subscription title, the cheapper one planning@@subscriptionPlanning1Title">
                  Subscricion 1
                </h3>
                <div class="text">
                  <p i18n="Subscription description, the cheapper one planning@@subscriptionPlanning1Description">
                    description 1
                  </p>
                </div>
                <a [ngx-scroll-to]="'#contact'" href="#contact" class="theme-btn btn-style-seven">
                  <span class="icon ti-arrow-circle-right"></span>
                  <p i18n="button subscription for free@@subscriptionPlanning1Button">
                    Registrate gratis 1
                  </p>
                </a>
              </div>
            </div>
            <div class="plan-column col-md-4 col-sm-4 col-xs-12">
              <div class="inner-box">
                <h5 i18n="Subscription price, the 2 subscription planning@@subscriptionPlanning2Price">
                  1€/mes
                </h5>
                <p i18n="per user, subscription 1@@perUser" class="per-user">
                  per user
                </p>
                <h3 i18n="Subscription title, the 2 subscription planning@@subscriptionPlanning2Title">
                  Subscricion 2
                </h3>
                <div class="text">
                  <p i18n="Subscription description, the 2 subscription planning@@subscriptionPlanning2Description">
                    description 2
                  </p>
                </div>
                <a [ngx-scroll-to]="'#contact'" href="#contact" class="theme-btn btn-style-seven">
                  <span class="icon ti-arrow-circle-right"></span>
                  <p i18n="button subscription for free@@subscriptionPlanning2Button">
                    Registrate gratis 2
                  </p>
                </a>
              </div>
            </div>
            <div class="plan-column col-md-4 col-sm-4 col-xs-12">
              <div class="inner-box">
                <h5 i18n="Subscription price, the 3 subscription planning@@subscriptionPlanning3Price">
                  3€/mes
                </h5>
                <p i18n="per user, subscription 1@@perUser" class="per-user">
                  per user
                </p>
                <h3 i18n="Subscription title, the 3 subscription planning@@subscriptionPlanning3Title">
                  Registrate gratis 3
                </h3>
                <div class="text">
                  <p i18n="Subscription description,the 3 subscription planning@@subscriptionPlanning3Description">
                    description 3
                  </p>
                </div>
                <a [ngx-scroll-to]="'#contact'" href="#contact" class="theme-btn btn-style-seven">
                  <span class="icon ti-arrow-circle-right"></span>
                  <p i18n="button subscription for free@@subscriptionPlanning3Button">
                    Registrate gratis
                  </p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->

  <section class="get-app-section" id="download">
    <div class="auto-container">
      <div class="sec-title-two centered">
        <h2 i18n="Get The App title@@getTheAppTitle">
          Descargate nuestra app
        </h2>
        <div class="desc-text">
          <p i18n="Get app explain text@@getTheAppText">
            Descargate nuestra app explicación
          </p>
        </div>
      </div>
      <div class="links-outer">
        <ul class="get-app-links clearfix">
          <li>
            <a href="https://itunes.apple.com/de/app/iq-sales/id1403738940" class="link-box">
              <div class="icon-box">
                <span class="icon">
                  <img src="assets/spring/icons/apple-icon.png" alt="Apple store icon">
                </span>
              </div>
              <span class="text" i18n="Available on@@avalaibleOnTextAppStore">Disponible en</span>
              <span class="title">App Store</span>
            </a>
          </li>
          <li>
            <a href="https://play.google.com/store/apps/details?id=iqsales.cloud" class="link-box">
              <div class="icon-box">
                <span class="icon">
                  <img src="assets/spring/icons/android-icon.png" alt="Google play icon">
                </span>
              </div>
              <span class="text" i18n="Available on@@avalaibleOnTextGooglePlay">Disponible en</span>
              <span class="title">Google Play</span>
            </a>
          </li>
          <li>
            <a href="https://iqsales.value-iq.com/" class="link-box">
              <div class="icon-box">
                <span class="icon">
                  <img src="assets/spring/icons/pwa-icon.png" alt="PWA icon">
                </span>
              </div>
              <span class="text" i18n="Available on@@avalaibleOnTextWeb">Disponible en</span>
              <span class="title">Web</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section class="contact-section" id="contact">
    <div class="auto-container">
      <div class="row clearfix">
        <div class="form-column col-md-6 col-md-offset-3 col-sm-12 col-xs-12">
          <div class="sec-title">
            <h2 i18n="Form section: header form@@formSectionHeaderForm">Crea tu cuenta en IQ Sales</h2>
            <div class="desc-text">
              <p i18n="Form section: subtitle form@@formSectionSubtitleForm">
                Dispondrás de 1 mes gratis sin necesidad de introducir tu tarjeta de crédito.
              </p>
            </div>
          </div>
          <div class="default-form contact-form">
            <app-form (sendFormEvent)="sendForm($event)" [sended]="formSended" [loadingButton]="loadings.form"
              [errorForm]="errorForm"></app-form>
          </div>
        </div>
      </div>
    </div>
  </section>

  <footer class="footer-style-one">
    <div class="footer-upper">
      <div class="auto-container">
        <div class="row clearfix">
          <div class="big-column col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <div class="footer-column">
              <div class="footer-widget about-widget">
                <div class="widget-inner">
                  <div class="logo">
                    <img src="assets/logos/logo.svg" i18n-alt="Logo" alt="logotipo">
                  </div>
                  <div class="copyright">&copy; {{ year | date:'yyyy' }} IQ Sales</div>
                </div>
              </div>
            </div>
          </div>
          <div class="big-column col-lg-3 col-md-12 col-sm-12 col-xs-12">
            <div class="row clearfix">
              <div class="footer-column col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="footer-widget links-widget">
                  <div class="widget-inner">
                    <h3 i18n="Footer links: Us, title@@footerLinksUsTitle">Nosotros</h3>
                    <div class="links">
                      <ul>
                        <li>
                          <a i18n="Footer: Contact with Us@@footerContactWithUs"
                            href="mailto:sales@iqsales.cloud">Contactanos</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer-column col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="footer-widget follow-widget">
                  <div class="widget-inner">
                    <h3 i18n="Contact with us@@contactWithUs">
                      Contacto
                    </h3>
                    <div class="social-links">
                      <ul class="clearfix">
                        <li>
                          <a href="https://www.linkedin.com/showcase/iqsales/">
                            <span class="fa fa-linkedin"></span>
                          </a>
                        </li>
                        <li>
                          <a href="mailto:sales@iqsales.cloud">
                            <span class="fa fa-envelope"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="big-column language-column col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <p i18n="footer section: title language: Change language@@footerSectionChangeLanguage">Cambiar de idioma</p>
            <ul>
              <li *ngIf="language !== 'de'" hreflang="de">
                <a href="/de">Deutsch</a>
              </li>
              <li *ngIf="language !== 'en'" hreflang="en">
                <a href="/en">English</a>
              </li>
              <li *ngIf="language !== 'es'" hreflang="es">
                <a href="/es">Español</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>