import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { IForm } from "../interfaces/iform";
import { environment } from "../../environments/environment";
import { ENDPOINTS } from "../constants";

@Injectable({
  providedIn: "root",
})
export class ProviderService {
  constructor(private http: HttpClient) {}

  public sendForm(form: IForm): Promise<{ ok: boolean; error: string }> {
    return new Promise((resolve, reject) => {
      this.http
        .post<{ ok: boolean; error: string }>(
          `${environment.protocol}://${environment.api}/${ENDPOINTS.REGISTER}`,
          form,
        )
        .subscribe(
          () => resolve({ ok: true, error: null }),
          ({ error }) => {
            let errorMessage = "Something was wrong, try again";
            if (error.errors) {
              Object.values(error.errors).forEach(
                (field: { param: string; msg: string }) =>
                  (errorMessage = `${field.param}: ${field.msg}`),
              );
            }
            reject({ ok: false, error: errorMessage });
          },
        );
    });
  }
}
