import localeEs from "@angular/common/locales/es";
import localeDe from "@angular/common/locales/de";
import { registerLocaleData } from "@angular/common";
import { environment } from "../environments/environment";
function getLocale() {
    if (environment.language === "es") {
        registerLocaleData(localeEs, environment.language);
    }
    if (environment.language === "de") {
        registerLocaleData(localeDe, environment.language);
    }
    return environment.language;
}
var ɵ0 = getLocale();
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
