import { Component, Output, EventEmitter, Input } from "@angular/core";
import { IForm } from "../../interfaces/iform";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.scss"],
})
export class FormComponent {
  @Input() loadingButton: boolean = false;
  @Input() sended: boolean = false;
  @Input() errorForm: string;
  public formErrors: {
    businessName: boolean;
    contactName: boolean;
    email: boolean;
    phone: boolean;
    numberOfStores: boolean;
    ERP: boolean;
    ERPCustom: boolean;
  } = {
    businessName: false,
    contactName: false,
    email: false,
    phone: false,
    numberOfStores: false,
    ERP: false,
    ERPCustom: false,
  };
  public form: IForm = {
    businessName: null,
    contactName: null,
    email: null,
    phone: null,
    numberOfStores: null,
    ERP: null,
    ERPCustom: "",
  };
  @Output() sendFormEvent: EventEmitter<IForm> = new EventEmitter();

  public sendForm(): void {
    this.cleanErrors();
    if (!this.form.businessName) {
      this.formErrors.businessName = true;
    }
    if (!this.form.contactName) {
      this.formErrors.contactName = true;
    }
    if (this.form.numberOfStores === null) {
      this.formErrors.numberOfStores = true;
    }
    if (this.form.ERP === null) {
      this.formErrors.numberOfStores = true;
    } else if (this.form.ERP === "Others" && !this.form.ERPCustom) {
      this.formErrors.ERPCustom = true;
    }
    if (!this.form.phone) {
      this.formErrors.phone = true;
    }
    if (!this.isValidEmail(this.form.email)) {
      this.formErrors.email = true;
    }

    if (Object.values(this.formErrors).filter((error) => error).length === 0) {
      this.sendFormEvent.emit(this.form);
    }
  }

  private isValidEmail(email: string): boolean {
    // tslint:disable-next-line
    const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexEmail.test(email);
  }

  private cleanErrors(): void {
    Object.keys(this.formErrors).forEach(
      (input) => (this.formErrors[input] = false),
    );
  }
}
