<form *ngIf="!sended" id="contact-form" (onsubmit)="sendForm()">
  <div class="form-group">
    <input type="text" name="businessName" [class.ng-invalid]="formErrors.businessName" [class.ng-touched]="formErrors.businessName"
      [(ngModel)]="form.businessName" placeholder="Nombre de la Empresa" i18n-placeholder="Placeholder input name" required
    />
    <span [class.-is-showed]="formErrors.businessName" i18n class="error-input">
      Necesitamos el nombre de tu empresa
    </span>
  </div>
  <div class="form-group">
    <input type="text" name="contactName" [(ngModel)]="form.contactName" [class.ng-invalid]="formErrors.contactName" [class.ng-touched]="formErrors.contactName"
      placeholder="Tu nombre completo" i18n-placeholder="Form section: Placeholder input contact name" required />
    <span [class.-is-showed]="formErrors.contactName" i18n class="error-input">
      Necesitamos un nombre de contacto
    </span>
  </div>
  <div class="form-group">
    <select name="stores" [(ngModel)]="form.ERP" [class.ng-invalid]="formErrors.ERP" [class.ng-touched]="formErrors.numberOfStores"
      required>
      <option i18n="Form section: Form option: placeholder select ERP" disabled value="null">¿Que ERP estais utilizando?</option>
      <option i18n="Form section: Form option: select ERP Advarics" value="Advarics">Advarics</option>
      <option i18n="Form section: Form option: select ERP Bits" value="Bits (Brandt Software)">Bits (Brandt Software)</option>
      <option i18n="Form section: Form option: select ERP Futura" value="Futura">Futura</option>
      <option i18n="Form section: Form option: select ERP Hiltes" value="Futura">Hiltes</option>
      <option i18n="Form section: Form option: select ERP Hoeltl" value="Höltl">Höltl</option>
      <option i18n="Form section: Form option: select ERP Microsoft Navision" value="Microsoft Navision">Microsoft Navision</option>
      <option i18n="Form section: Form option: select ERP SAGE" value="SAGE">SAGE</option>
      <option i18n="Form section: Form option: select ERP Select Line" value="Select Line">Select Line</option>
      <option i18n="Form section: Form option: select ERP Others" value="Others">Others</option>
    </select>
    <span [class.-is-showed]="formErrors.ERP" i18n class="error-input">
      Selecciona una opción
    </span>
  </div>
  <div *ngIf="form.ERP === 'Others'" class="form-group">
    <input type="text" name="ERP" [class.ng-invalid]="formErrors.ERPCustom" [(ngModel)]="form.ERPCustom" placeholder="Nombre de tu ERP"
      i18n-placeholder="Form section: Placeholder input ERP" required />
    <span [class.-is-showed]="formErrors.ERPCustom" i18n class="error-input">
      Introduce tu ERP
    </span>
  </div>
  <div class="form-group">
    <select name="stores" [class.ng-invalid]="formErrors.numberOfStores" [class.ng-touched]="formErrors.numberOfStores" [(ngModel)]="form.numberOfStores">
      <option i18n="Form section: Form option: placeholder select stores" disabled value="null">Numero de tiendas</option>
      <option i18n="Form section: Form option: no stores" value="0">Todavía ninguna</option>
      <option i18n="Form section: Form option: one store" value="1">1 Tienda</option>
      <option i18n="Form section: Form option: 2 to 5 stores" value="2-5">Entre 2 y 5</option>
      <option i18n="Form section: Form option: more than 5" value=">5">Más de 5</option>
    </select>
    <span [class.-is-showed]="formErrors.numberOfStores" i18n class="error-input">
      Selecciona una opción
    </span>
  </div>
  <div class="form-group">
    <input type="tel" name="phone" [(ngModel)]="form.phone" [class.ng-touched]="formErrors.phone" placeholder="Telefono de contacto"
      i18n-placeholder="Form section: Placeholder input phone number" required />
    <span [class.-is-showed]="formErrors.phone" i18n class="error-input">
      Por favor introduce tu teléfono
    </span>
  </div>
  <div class="form-group">
    <input type="text" name="email" [class.ng-touched]="formErrors.email" [(ngModel)]="form.email" [class.ng-invalid]="formErrors.email"
      placeholder="Email" i18n-placeholder="Placeholder input email" required />
    <span [class.-is-showed]="formErrors.email" i18n class="error-input">
      Este no es un email correcto
    </span>
  </div>

  <div class="form-group">
    <button type="submit" class="theme-btn btn-style-two" (click)="sendForm()">
      <span *ngIf="!loadingButton" i18n="Form button send" i18n="Form section: Send button">Enviar</span>
      <span *ngIf="!loadingButton" class="icon ti-arrow-circle-right"></span>
      <span *ngIf="loadingButton" class="icon ti-reload"></span>
    </button>
  </div>
  <div class="form-group">
    <span *ngIf="errorForm" class="error-message">
      <p>
        {{ errorForm }}
      </p>
    </span>
  </div>
</form>
<div class="form-success" *ngIf="sended">
  <span class="icon ti-email"></span>
  <p>
    <span i18n="Confirm form sended: first line">
      Te hemos enviado un email para confirmar
    </span>
    <br>
    <span i18n="Confirm form sended: second line">
      tu dirección de correo
    </span>
    <br> {{form.email}} </p>
</div>