import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { environment } from "../environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(private meta: Meta, private title: Title) {
    this.addMetatags(environment.language);
  }

  private addMetatags(language: "es" | "en" | "de"): void {
    let title = "";
    let description = "";
    let keywords = "";
    if (language === "de") {
      title = "IQ Sales CRM App im Einzelhandel";
      description = `Die CRM App zur Absatzsteigerung im Einzelhandel.
      Mit der mobilen CRM App IQ Sales haben Sie Ihre Kunden stets im Blick.
      Bilden Sie eine Beziehung zu Ihren Kunden, und bieten Sie ihnen ein persönliches Einkaufs-Erlebnis.`;
      keywords = "CRM, ERP";
    } else if (language === "es") {
      title = "IQ Sales App CRM para comerciales";
      description = `La app CRM que potencia las ventas de tu comercio.
      Con IQSales realiza el seguimiento de tus clientes potenciales desde el primer momento.
      Conoce a tu cliente y mejore su experiencia de compra.`;
      keywords = "CRM, ERP, Comercio";
    } else {
      title = "IQ Sales CRM App for sales reps";
      description = `The CRM App that boosts your retail sales.
      Your customers at your fingertip:
       With the mobile CRM App IQ Sales youget to know your customers and you can offer a personal shopping experience.`;
      keywords = "CRM, ERP";
    }
    this.title.setTitle(title);
    this.meta.addTags(
      [
        { name: "description", content: description },
        { name: "keywords", content: keywords },
        { name: "keywords", content: keywords },
        { property: "og:description", content: description },
        { property: "og:locale", content: language },
        { property: "og:title", content: title },
      ],
      true,
    );
  }
}
