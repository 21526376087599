import * as __NgCli_locale_1 from "@angular/common/locales/en";
import * as __NgCli_locale_2 from "@angular/common";
__NgCli_locale_2.registerLocaleData(__NgCli_locale_1.default);
import { enableProdMode, TRANSLATIONS_FORMAT, MissingTranslationStrategy, } from "@angular/core";
import { environment } from "./environments/environment";
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory, {
    missingTranslation: MissingTranslationStrategy.Warning,
    providers: [{ provide: TRANSLATIONS_FORMAT, useValue: "xtb" }],
})
    .catch(function (err) { return console.log(err); });
