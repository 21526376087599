import {
  enableProdMode,
  TRANSLATIONS_FORMAT,
  MissingTranslationStrategy,
} from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule, {
    missingTranslation: MissingTranslationStrategy.Warning,
    providers: [{ provide: TRANSLATIONS_FORMAT, useValue: "xtb" }],
  })
  .catch((err) => console.log(err));
