import { Component, OnInit } from "@angular/core";
import { IForm } from "../interfaces/iform";
import { ProviderService } from "../services/provider.service";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.scss"],
})
export class HomePageComponent implements OnInit {
  public year: Date;
  public showMenu: boolean = false;
  public language: "en" | "es" | "de" = environment.language;
  public loadings: { general: boolean; form: boolean } = {
    general: false,
    form: false,
  };
  public carrousels: {
    customer: number;
    salesRep: number;
    management: number;
  } = {
    customer: 0,
    salesRep: 0,
    management: 0,
  };
  public formSended: boolean = false;
  public errorForm: string;

  constructor(private prv: ProviderService) {}

  public ngOnInit(): void {
    this.year = new Date();
  }

  public toogleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  public async sendForm(form: IForm): Promise<void> {
    this.loadings.form = true;
    try {
      await this.prv.sendForm(form);
      this.formSended = true;
    } catch (error) {
      this.errorForm = error.error;
    }
    this.loadings.form = false;
  }
}
