import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomePageComponent } from "./home-page/home-page.component";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";
import { FormComponent } from "./home-page/form/form.component";
import { HttpClientModule } from "@angular/common/http";
import localeEs from "@angular/common/locales/es";
import localeDe from "@angular/common/locales/de";
import { registerLocaleData } from "@angular/common";
import { environment } from "../environments/environment";

function getLocale(): "en" | "es" | "de" {
  if (environment.language === "es") {
    registerLocaleData(localeEs, environment.language);
  }
  if (environment.language === "de") {
    registerLocaleData(localeDe, environment.language);
  }
  return environment.language;
}
@NgModule({
  declarations: [AppComponent, HomePageComponent, FormComponent],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ScrollToModule.forRoot(),
    BrowserModule.withServerTransition({ appId: "iq-sales" }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: getLocale() }],
  bootstrap: [AppComponent],
})
export class AppModule {}
