import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { ENDPOINTS } from "../constants";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ProviderService = /** @class */ (function () {
    function ProviderService(http) {
        this.http = http;
    }
    ProviderService.prototype.sendForm = function (form) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http
                .post(environment.protocol + "://" + environment.api + "/" + ENDPOINTS.REGISTER, form)
                .subscribe(function () { return resolve({ ok: true, error: null }); }, function (_a) {
                var error = _a.error;
                var errorMessage = "Something was wrong, try again";
                if (error.errors) {
                    Object.values(error.errors).forEach(function (field) {
                        return (errorMessage = field.param + ": " + field.msg);
                    });
                }
                reject({ ok: false, error: errorMessage });
            });
        });
    };
    ProviderService.ngInjectableDef = i0.defineInjectable({ factory: function ProviderService_Factory() { return new ProviderService(i0.inject(i1.HttpClient)); }, token: ProviderService, providedIn: "root" });
    return ProviderService;
}());
export { ProviderService };
